<template>
  <ChangePasswordMaintenance />
</template>

<script>
import ChangePasswordMaintenance from '@/components/pages/maintenance/ChangePasswordMaintenance'

export default {
  components: {
    ChangePasswordMaintenance
  }
}
</script>
