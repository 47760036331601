<template>
  <transition
    appear
    enter-active-class="animated animate__fadeIn"
    leave-active-class="animated animate__fadeOut"
  >
    <q-card :class="containerClass" v-if="visible">
      <q-card-section>
        <div class="row items-center justify-between gap-2">
          <div class="row items-center gap-2">
            <q-icon :name="icon" color="white" size="sm" />
            <p class="text-white">
              {{ message }}
            </p>
          </div>
          <Button icon="close" size="sm" round @click="close" />
        </div>
      </q-card-section>
    </q-card>
  </transition>
</template>

<script>
import { computed } from 'vue'

import Button from '@/components/Button'

export default {
  name: 'Notif',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    message: {
      type: String,
      default: null
    }
  },
  components: { Button },
  setup(props, { emit }) {
    const containerClass = computed(() => {
      return [
        {
          'bg-primary': props.variant === 'primary',
          'bg-success': props.variant === 'success',
          'bg-warning': props.variant === 'warning',
          'bg-danger': props.variant === 'danger',
          'bg-info': props.variant === 'info'
        }
      ]
    })

    const icon = computed(() => {
      switch (props.variant) {
        case 'success': {
          return 'check_circle'
        }
        case 'warning': {
          return 'warning'
        }
        case 'danger': {
          return 'error'
        }
        case 'info': {
          return 'info'
        }
        default: {
          return 'info'
        }
      }
    })

    const close = () => {
      emit('close')
    }

    return {
      containerClass,
      icon,
      close
    }
  }
}
</script>
