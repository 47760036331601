<template>
  <PageHeader breadcrumb="Maintenance" title="Change Password" />
  <PageContent>
    <div class="column gap-4">
      <Notif
        :visible="isSuccess"
        variant="success"
        message="Password successfully changed."
        @close="onHideNotif"
      />

      <q-form ref="passwordForm" @submit.prevent.stop="onSubmit">
        <q-card class="q-pa-md max-w-md">
          <q-card-section class="q-gutter-lg">
            <div>
              <FormInput
                label="Current Password"
                minlength="6"
                maxlength="12"
                tabindex="1"
                password
                v-model="currentPassword"
                :rules="[
                  VALIDATION.required,
                  val => VALIDATION.requiredLength(val, 6, 12)
                ]"
              />
            </div>

            <div class="q-mt-xl row items-center gap-2">
              <q-icon name="info" color="primary" size="xs" />
              <p class="font-bold">Note:</p>
              <ul>
                <li>Password length should be between 6 and 12 characters.</li>
                <li>Do not use previously used password.</li>
              </ul>
            </div>

            <div>
              <FormInput
                label="New Password"
                minlength="6"
                maxlength="12"
                tabindex="2"
                password
                v-model="newPassword"
                :rules="[
                  VALIDATION.required,
                  val => VALIDATION.requiredLength(val, 6, 12)
                ]"
              />
            </div>

            <div>
              <FormInput
                label="Confirm New Password"
                minlength="6"
                maxlength="12"
                tabindex="3"
                password
                v-model="verifyPassword"
                :rules="[
                  VALIDATION.required,
                  val => VALIDATION.requiredLength(val, 6, 12),
                  val => VALIDATION.verifyPassword(val, newPassword)
                ]"
              />
            </div>
          </q-card-section>

          <q-card-actions class="q-mt-md row justify-end">
            <Button
              type="submit"
              label="Change Password"
              variant="primary"
              tabindex="4"
              :loading="loadingUpdate"
              :disabled="loadingUpdate"
            />
          </q-card-actions>
        </q-card>
      </q-form>
    </div>
  </PageContent>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FormInput } from '@/components/inputs'
import Button from '@/components/Button'
import Notif from '@/components/Notif'

import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'ChangePasswordMaintenance',
  components: {
    PageHeader,
    PageContent,
    FormInput,
    Button,
    Notif
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      post: postUpdate
    } = fetchData()

    const passwordForm = ref(null)
    const currentPassword = ref(null)
    const newPassword = ref(null)
    const verifyPassword = ref(null)
    const isSuccess = ref(false)

    const onSubmit = () => {
      const payload = {
        old_password: currentPassword.value,
        new_password: newPassword.value
      }
      postUpdate('v1/user/change-password', payload)
    }

    const onReset = () => {
      passwordForm.value.resetValidation()
      currentPassword.value = null
      newPassword.value = null
      verifyPassword.value = null
    }

    const onHideNotif = () => {
      isSuccess.value = false
    }

    onBeforeMount(() => {
      watch([dataUpdate, errorUpdate, loadingUpdate], () => {
        if (loadingUpdate.value) {
          isSuccess.value = false
        } else {
          if (errorUpdate?.value) {
            showToast(
              errorUpdate?.value?.errors
                ? errorUpdate?.value.errors[0].msg
                : errorUpdate?.value?.message,
              'danger'
            )
          } else if (dataUpdate?.value) {
            if (dataUpdate?.value) {
              onReset()
              isSuccess.value = true
              showToast('Password successfully changed.', 'success')
            }
          }
        }
      })
    })

    return {
      VALIDATION,
      passwordForm,
      currentPassword,
      newPassword,
      verifyPassword,
      isSuccess,
      loadingUpdate,
      onSubmit,
      onHideNotif
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}
</style>
